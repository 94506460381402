import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCardCategory, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tags = ({ data, location, pageContext }) => {
    const sanityCategories = data.allSanityCategory.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                title="Deler av den skandinaviske verden"
                //description="Սկանդինավյան աշխարհի բաժիններ"
                type="series"
                language='no'
            />
            <Layout language='no'>
                <div className="container">
                    <h1>Seksjoner</h1>
                    <section className="post-feed">
                        {sanityCategories != null && sanityCategories.filter(edge => edge.node.lang == 'no').map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCardCategory key={node.id} category={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

export default Tags

export const pageQuery = graphql`
    query allSanityCategoryNO {
        allSanityCategory {
            edges {
            node {
                name
                description
                slug {
                    current
                }
                lang
                image {
                    alt
                    hotspot {
                        height
                        width
                        x
                        y
                      }
                      crop {
                        bottom
                        left
                        right
                        top
                      }
                      asset {
                        _id
                        metadata {
                          preview: lqip
                          dimensions {
                            height
                            width
                          }
                        }
                      }
                }
            }
        }
    }
}
`
